<template>
  <div class="my_honour">
    <div class="warpper">
      <h2 class="title">我的荣誉</h2>
      <!-- <h3 class="subtitle">荣誉</h3> -->
      <template v-if="honourList.length>0">
        <div class="honour-section flex-algin">
          <!-- <div class="item">
          <h4 class="f18">暂无荣誉内容</h4>
        </div> -->
          <div class="item" v-for="(item,index) in honourList" :key="index">
            <h4 class="f16 wes">{{item.name}}</h4>
            <!-- <img :src="downloadURL + item.identifier" alt="" @click="enlarge(item)"/> -->
            <!-- <img :src="downloadURL + item.identifier" alt="" @click="openImg(item)" /> -->
            <img :src="downloadURL + item.identifier" alt="" @click="previewImage(index)" />
            <p>{{item.date}}</p>
          </div>
          <!-- <div class="item" v-for="(item,index) in honourList" :key="index">
          <h4 class="f18">{{item.name}}</h4>
          <img :src="item.img" alt="" @click="enlarge(item)"/>
          <p>{{item.date}}</p>
        </div> -->
          <!-- <div class="item">
          <h4 class="f18">优秀指导教师一等奖</h4>
          <img src="../../assets/images/my/honor1.jpg" alt="" />
          <p>2020.06.02</p>
        </div>
        <div class="item">
          <h4 class="f18">优秀指导教师一等奖</h4>
           <img src="../../assets/images/my/honor2.jpg" alt="" />
          <p>2020.07.01</p>
        </div>
        <div class="item">
          <h4 class="f18">优秀指导教师一等奖</h4>
          <img src="../../assets/images/my/honor3.jpg" alt="" />
          <p>2020.09.10</p>
        </div> -->
          <!-- <div class="item">
          <h4 class="f18">阅读之星</h4>
          <img src="" alt="" />
          <p>2020.09.01</p>
        </div> -->
        </div>
      </template>
      <el-empty description="暂无荣誉" v-else></el-empty>
      <!-- <h3 class="subtitle">获奖</h3>
      <div class="award-section flex-algin">
        <div class="item">
          <h4 class="f18">暂无获奖内容</h4>
          <img src="" alt="" /> 
          <p>2020.09.01</p> 
        </div>
      </div> -->
    </div>
    <el-dialog :title="enlargeInfo.name" :visible.sync="centerDialogVisible" width="50%" top="10vh" center lock-scroll
      :modal="true">
      <img :src="enlargeInfo.img" alt="">
      <!-- <span slot="footer" class="dialog-footer"> -->
      <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
      <!-- <el-button type="primary" @click="golive">进入直播</el-button> -->
      <!-- </span> -->
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        honourList: [
          // {
          //   name:'优秀指导教师一等奖',
          //   img: require("../../assets/images/my/honor1.jpg"),
          //   date:'2020.06.02'
          // },{
          //   name:'优秀指导教师一等奖',
          //   img: require("../../assets/images/my/honor2.jpg"),
          //   date:'2020.07.01'
          // },{
          //   name:'优秀指导教师一等奖',
          //   img: require("../../assets/images/my/honor3.jpg"),
          //   date:'2020.09.10'
          // }
        ],
        centerDialogVisible: false,
        enlargeInfo: {},
        imageList: [],
      }
    },
    methods: {
      enlarge(item) {
        this.enlargeInfo = item
        setTimeout(() => {
          this.centerDialogVisible = true;
        }, 100)
      },
      openImg(item) {
        window.open(`${this.downloadURL}${item.identifier}`)
      },
      // 预览图片
      previewImage(index) {
        this.$previewImgs(this.imageList, index)
      },
      async getHonourList() {
        let resData = await this.$Api.My.getAwardList()
        // console.log(resData)
        if (!resData) return;
        this.honourList = resData.data
        this.honourList.map(item => {
          this.imageList.push(`${this.downloadURL}${item.identifier}`);
        })
      },
    },
    mounted() {
      this.getHonourList();
    },
    activated() {
      this.getHonourList();
    },
  };
</script>

<style lang="less">
  .my_honour {
    border-radius: 10px;
    background: #ffffff;

    .warpper {
      padding-left: 9px;
    }

    .title {
      border-bottom: 1px solid #f9f9f9;
      padding-left: 35px;
      line-height: 69px;

      font-size: 24px;
    }

    .subtitle {
      margin-top: 22px;
      padding-left: 40px;
      font-size: 22px;
    }

    .honour-section,
    .award-section {
      padding: 40px 32px 0;
      text-align: center;
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;

      .item {
        flex: 0 0 33.33%;
        // width: calc(100% / 3);
        margin-bottom: 16px;

        img {
          display: block;
          margin: 10px auto 4px;
          // width: 227px;
          width: 95%;
          height: 162px;
          margin-bottom: 4px;
          // background-color: palevioletred;
        }

        p.time {
          font-size: 16px;
          color: #666;
        }
      }
    }

    .honour-section {
      // border-bottom: 1px solid #f9f9f9;
      padding-bottom: 26px;
    }

    /deep/.el-dialog {}

    // /deep/.el-dialog__header {
    //     background-color: #2373EC;
    //     padding: 20px;

    //     .el-dialog__title {
    //         color: #fff;
    //     }

    //     .el-icon-close:before {
    //         color: #fff;
    //     }
    // }

    /deep/.el-dialog__body {
      img {
        max-width: 100%;
        width: 100%;
      }
    }

    /deep/.el-dialog__footer {
      .el-button--primary {
        background-color: #2373EC;
        border-color: #2373EC;
      }
    }
  }

  @media screen and (max-width: 1250px) {
    .my_honour {
      max-width: 660px;
      width: 660px;

      .honour-section,
      .award-section {
        .item {
          flex: 0 0 50%;
          margin-bottom: 16px;
        }
      }
    }
  }
</style>